import { default as _91slug_93jDgz6Q7vMsMeta } from "/srv/website/pages/blog/[slug].vue?macro=true";
import { default as index9lNg35cxZkMeta } from "/srv/website/pages/blog/index.vue?macro=true";
import { default as contactumzzQgZ9f2Meta } from "/srv/website/pages/contact.vue?macro=true";
import { default as electronicsCELGbEIYZsMeta } from "/srv/website/pages/electronics.vue?macro=true";
import { default as _404sFhaDk3rz8Meta } from "/srv/website/pages/error/404.vue?macro=true";
import { default as faqbvCaMk3Du8Meta } from "/srv/website/pages/faq.vue?macro=true";
import { default as handyswNtw4A6qW1Meta } from "/srv/website/pages/handys.vue?macro=true";
import { default as indexauuitsYJu3Meta } from "/srv/website/pages/index.vue?macro=true";
import { default as jobsaaXmN5F7V6Meta } from "/srv/website/pages/jobs.vue?macro=true";
import { default as legalY8nl3n5XfSMeta } from "/srv/website/pages/legal.vue?macro=true";
import { default as login777aTIUAiSMeta } from "/srv/website/pages/login.vue?macro=true";
import { default as berlinTbWPltRhnXMeta } from "/srv/website/pages/old-clothes/berlin.vue?macro=true";
import { default as bonn3FpG603ksUMeta } from "/srv/website/pages/old-clothes/bonn.vue?macro=true";
import { default as cologneVuPZ9rC7VbMeta } from "/srv/website/pages/old-clothes/cologne.vue?macro=true";
import { default as frankfurtXBnX3YsT4xMeta } from "/srv/website/pages/old-clothes/frankfurt.vue?macro=true";
import { default as hamburgMhpQGpgq2pMeta } from "/srv/website/pages/old-clothes/hamburg.vue?macro=true";
import { default as index9fURhySR4RMeta } from "/srv/website/pages/old-clothes/index.vue?macro=true";
import { default as munich163FFbv2HDMeta } from "/srv/website/pages/old-clothes/munich.vue?macro=true";
import { default as confirmationgMXsRObATeMeta } from "/srv/website/pages/order/confirmation.vue?macro=true";
import { default as indexQfZ3EMY3bDMeta } from "/srv/website/pages/order/index.vue?macro=true";
import { default as thank_45youIiMv6wjsjoMeta } from "/srv/website/pages/order/thank-you.vue?macro=true";
import { default as tobytigerQjmK4TNqWfMeta } from "/srv/website/pages/partners/tobytiger.vue?macro=true";
import { default as privacynHR7ZEX8fIMeta } from "/srv/website/pages/privacy.vue?macro=true";
import { default as recycleablesNVAim536mCMeta } from "/srv/website/pages/recycleables.vue?macro=true";
import { default as registermykfPA5EoVMeta } from "/srv/website/pages/register.vue?macro=true";
import { default as reset_45passwordufSoUydTv2Meta } from "/srv/website/pages/reset-password.vue?macro=true";
import { default as detailsKGInT8y3RHMeta } from "/srv/website/pages/single/selfservice/details.vue?macro=true";
import { default as indexm919wjN412Meta } from "/srv/website/pages/single/selfservice/index.vue?macro=true";
import { default as social_45projectsg0R8X4GSS1Meta } from "/srv/website/pages/social-projects.vue?macro=true";
import { default as teamBh9n8dEMBTMeta } from "/srv/website/pages/team.vue?macro=true";
import { default as termsxY3dMBTJtEMeta } from "/srv/website/pages/terms.vue?macro=true";
import { default as dashboardGvWs3YtVJoMeta } from "/srv/website/pages/user/dashboard.vue?macro=true";
import { default as dataLvw4X2Bm54Meta } from "/srv/website/pages/user/data.vue?macro=true";
import { default as invoicesiWOLoz2nlqMeta } from "/srv/website/pages/user/invoices.vue?macro=true";
export default [
  {
    name: _91slug_93jDgz6Q7vMsMeta?.name ?? "blog-slug",
    path: _91slug_93jDgz6Q7vMsMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93jDgz6Q7vMsMeta || {},
    alias: _91slug_93jDgz6Q7vMsMeta?.alias || [],
    redirect: _91slug_93jDgz6Q7vMsMeta?.redirect,
    component: () => import("/srv/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: index9lNg35cxZkMeta?.name ?? "blog",
    path: index9lNg35cxZkMeta?.path ?? "/blog",
    meta: index9lNg35cxZkMeta || {},
    alias: index9lNg35cxZkMeta?.alias || [],
    redirect: index9lNg35cxZkMeta?.redirect,
    component: () => import("/srv/website/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contactumzzQgZ9f2Meta?.name ?? "contact",
    path: contactumzzQgZ9f2Meta?.path ?? "/kontakt",
    meta: contactumzzQgZ9f2Meta || {},
    alias: contactumzzQgZ9f2Meta?.alias || [],
    redirect: contactumzzQgZ9f2Meta?.redirect,
    component: () => import("/srv/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: electronicsCELGbEIYZsMeta?.name ?? "electronics",
    path: electronicsCELGbEIYZsMeta?.path ?? "/elektrogeraete",
    meta: electronicsCELGbEIYZsMeta || {},
    alias: electronicsCELGbEIYZsMeta?.alias || [],
    redirect: electronicsCELGbEIYZsMeta?.redirect,
    component: () => import("/srv/website/pages/electronics.vue").then(m => m.default || m)
  },
  {
    name: _404sFhaDk3rz8Meta?.name ?? "error-404",
    path: _404sFhaDk3rz8Meta?.path ?? "/:pathMatch(.*)*",
    meta: _404sFhaDk3rz8Meta || {},
    alias: _404sFhaDk3rz8Meta?.alias || [],
    redirect: _404sFhaDk3rz8Meta?.redirect,
    component: () => import("/srv/website/pages/error/404.vue").then(m => m.default || m)
  },
  {
    name: faqbvCaMk3Du8Meta?.name ?? "faq",
    path: faqbvCaMk3Du8Meta?.path ?? "/haeufige-fragen",
    meta: faqbvCaMk3Du8Meta || {},
    alias: faqbvCaMk3Du8Meta?.alias || [],
    redirect: faqbvCaMk3Du8Meta?.redirect,
    component: () => import("/srv/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: handyswNtw4A6qW1Meta?.name ?? "handys",
    path: handyswNtw4A6qW1Meta?.path ?? "/handys",
    meta: handyswNtw4A6qW1Meta || {},
    alias: handyswNtw4A6qW1Meta?.alias || [],
    redirect: handyswNtw4A6qW1Meta?.redirect,
    component: () => import("/srv/website/pages/handys.vue").then(m => m.default || m)
  },
  {
    name: indexauuitsYJu3Meta?.name ?? "index",
    path: indexauuitsYJu3Meta?.path ?? "/",
    meta: indexauuitsYJu3Meta || {},
    alias: indexauuitsYJu3Meta?.alias || [],
    redirect: indexauuitsYJu3Meta?.redirect,
    component: () => import("/srv/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: jobsaaXmN5F7V6Meta?.name ?? "jobs",
    path: jobsaaXmN5F7V6Meta?.path ?? "/jobs",
    meta: jobsaaXmN5F7V6Meta || {},
    alias: jobsaaXmN5F7V6Meta?.alias || [],
    redirect: jobsaaXmN5F7V6Meta?.redirect,
    component: () => import("/srv/website/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: legalY8nl3n5XfSMeta?.name ?? "legal",
    path: legalY8nl3n5XfSMeta?.path ?? "/impressum",
    meta: legalY8nl3n5XfSMeta || {},
    alias: legalY8nl3n5XfSMeta?.alias || [],
    redirect: legalY8nl3n5XfSMeta?.redirect,
    component: () => import("/srv/website/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: login777aTIUAiSMeta?.name ?? "login",
    path: login777aTIUAiSMeta?.path ?? "/anmelden",
    meta: login777aTIUAiSMeta || {},
    alias: login777aTIUAiSMeta?.alias || [],
    redirect: login777aTIUAiSMeta?.redirect,
    component: () => import("/srv/website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: berlinTbWPltRhnXMeta?.name ?? "old-clothes-berlin",
    path: berlinTbWPltRhnXMeta?.path ?? "/altkleider/berlin",
    meta: berlinTbWPltRhnXMeta || {},
    alias: berlinTbWPltRhnXMeta?.alias || [],
    redirect: berlinTbWPltRhnXMeta?.redirect,
    component: () => import("/srv/website/pages/old-clothes/berlin.vue").then(m => m.default || m)
  },
  {
    name: bonn3FpG603ksUMeta?.name ?? "old-clothes-bonn",
    path: bonn3FpG603ksUMeta?.path ?? "/old-clothes/bonn",
    meta: bonn3FpG603ksUMeta || {},
    alias: bonn3FpG603ksUMeta?.alias || [],
    redirect: bonn3FpG603ksUMeta?.redirect,
    component: () => import("/srv/website/pages/old-clothes/bonn.vue").then(m => m.default || m)
  },
  {
    name: cologneVuPZ9rC7VbMeta?.name ?? "old-clothes-cologne",
    path: cologneVuPZ9rC7VbMeta?.path ?? "/altkleider/koeln",
    meta: cologneVuPZ9rC7VbMeta || {},
    alias: cologneVuPZ9rC7VbMeta?.alias || [],
    redirect: cologneVuPZ9rC7VbMeta?.redirect,
    component: () => import("/srv/website/pages/old-clothes/cologne.vue").then(m => m.default || m)
  },
  {
    name: frankfurtXBnX3YsT4xMeta?.name ?? "old-clothes-frankfurt",
    path: frankfurtXBnX3YsT4xMeta?.path ?? "/altkleider/frankfurt",
    meta: frankfurtXBnX3YsT4xMeta || {},
    alias: frankfurtXBnX3YsT4xMeta?.alias || [],
    redirect: frankfurtXBnX3YsT4xMeta?.redirect,
    component: () => import("/srv/website/pages/old-clothes/frankfurt.vue").then(m => m.default || m)
  },
  {
    name: hamburgMhpQGpgq2pMeta?.name ?? "old-clothes-hamburg",
    path: hamburgMhpQGpgq2pMeta?.path ?? "/altkleider/hamburg",
    meta: hamburgMhpQGpgq2pMeta || {},
    alias: hamburgMhpQGpgq2pMeta?.alias || [],
    redirect: hamburgMhpQGpgq2pMeta?.redirect,
    component: () => import("/srv/website/pages/old-clothes/hamburg.vue").then(m => m.default || m)
  },
  {
    name: index9fURhySR4RMeta?.name ?? "old-clothes",
    path: index9fURhySR4RMeta?.path ?? "/altkleider",
    meta: index9fURhySR4RMeta || {},
    alias: index9fURhySR4RMeta?.alias || [],
    redirect: index9fURhySR4RMeta?.redirect,
    component: () => import("/srv/website/pages/old-clothes/index.vue").then(m => m.default || m)
  },
  {
    name: munich163FFbv2HDMeta?.name ?? "old-clothes-munich",
    path: munich163FFbv2HDMeta?.path ?? "/altkleider/muenchen",
    meta: munich163FFbv2HDMeta || {},
    alias: munich163FFbv2HDMeta?.alias || [],
    redirect: munich163FFbv2HDMeta?.redirect,
    component: () => import("/srv/website/pages/old-clothes/munich.vue").then(m => m.default || m)
  },
  {
    name: confirmationgMXsRObATeMeta?.name ?? "order-confirmation",
    path: confirmationgMXsRObATeMeta?.path ?? "/bestellen/ueberpruefen",
    meta: confirmationgMXsRObATeMeta || {},
    alias: confirmationgMXsRObATeMeta?.alias || [],
    redirect: confirmationgMXsRObATeMeta?.redirect,
    component: () => import("/srv/website/pages/order/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexQfZ3EMY3bDMeta?.name ?? "order",
    path: indexQfZ3EMY3bDMeta?.path ?? "/bestellen",
    meta: indexQfZ3EMY3bDMeta || {},
    alias: indexQfZ3EMY3bDMeta?.alias || [],
    redirect: indexQfZ3EMY3bDMeta?.redirect,
    component: () => import("/srv/website/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: thank_45youIiMv6wjsjoMeta?.name ?? "order-thank-you",
    path: thank_45youIiMv6wjsjoMeta?.path ?? "/bestellen/danke",
    meta: thank_45youIiMv6wjsjoMeta || {},
    alias: thank_45youIiMv6wjsjoMeta?.alias || [],
    redirect: thank_45youIiMv6wjsjoMeta?.redirect,
    component: () => import("/srv/website/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: tobytigerQjmK4TNqWfMeta?.name ?? "partners-tobytiger",
    path: tobytigerQjmK4TNqWfMeta?.path ?? "/tobytiger",
    meta: tobytigerQjmK4TNqWfMeta || {},
    alias: tobytigerQjmK4TNqWfMeta?.alias || [],
    redirect: tobytigerQjmK4TNqWfMeta?.redirect,
    component: () => import("/srv/website/pages/partners/tobytiger.vue").then(m => m.default || m)
  },
  {
    name: privacynHR7ZEX8fIMeta?.name ?? "privacy",
    path: privacynHR7ZEX8fIMeta?.path ?? "/datenschutz",
    meta: privacynHR7ZEX8fIMeta || {},
    alias: privacynHR7ZEX8fIMeta?.alias || [],
    redirect: privacynHR7ZEX8fIMeta?.redirect,
    component: () => import("/srv/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: recycleablesNVAim536mCMeta?.name ?? "recycleables",
    path: recycleablesNVAim536mCMeta?.path ?? "/recycleables",
    meta: recycleablesNVAim536mCMeta || {},
    alias: recycleablesNVAim536mCMeta?.alias || [],
    redirect: recycleablesNVAim536mCMeta?.redirect,
    component: () => import("/srv/website/pages/recycleables.vue").then(m => m.default || m)
  },
  {
    name: registermykfPA5EoVMeta?.name ?? "register",
    path: registermykfPA5EoVMeta?.path ?? "/registrieren",
    meta: registermykfPA5EoVMeta || {},
    alias: registermykfPA5EoVMeta?.alias || [],
    redirect: registermykfPA5EoVMeta?.redirect,
    component: () => import("/srv/website/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordufSoUydTv2Meta?.name ?? "reset-password",
    path: reset_45passwordufSoUydTv2Meta?.path ?? "/passwort-zuruecksetzen",
    meta: reset_45passwordufSoUydTv2Meta || {},
    alias: reset_45passwordufSoUydTv2Meta?.alias || [],
    redirect: reset_45passwordufSoUydTv2Meta?.redirect,
    component: () => import("/srv/website/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: detailsKGInT8y3RHMeta?.name ?? "single-selfservice-details",
    path: detailsKGInT8y3RHMeta?.path ?? "/selfservice/details",
    meta: detailsKGInT8y3RHMeta || {},
    alias: detailsKGInT8y3RHMeta?.alias || [],
    redirect: detailsKGInT8y3RHMeta?.redirect,
    component: () => import("/srv/website/pages/single/selfservice/details.vue").then(m => m.default || m)
  },
  {
    name: indexm919wjN412Meta?.name ?? "single-selfservice",
    path: indexm919wjN412Meta?.path ?? "/selfservice",
    meta: indexm919wjN412Meta || {},
    alias: indexm919wjN412Meta?.alias || [],
    redirect: indexm919wjN412Meta?.redirect,
    component: () => import("/srv/website/pages/single/selfservice/index.vue").then(m => m.default || m)
  },
  {
    name: social_45projectsg0R8X4GSS1Meta?.name ?? "social-projects",
    path: social_45projectsg0R8X4GSS1Meta?.path ?? "/soziale-projekte",
    meta: social_45projectsg0R8X4GSS1Meta || {},
    alias: social_45projectsg0R8X4GSS1Meta?.alias || [],
    redirect: social_45projectsg0R8X4GSS1Meta?.redirect,
    component: () => import("/srv/website/pages/social-projects.vue").then(m => m.default || m)
  },
  {
    name: teamBh9n8dEMBTMeta?.name ?? "team",
    path: teamBh9n8dEMBTMeta?.path ?? "/team",
    meta: teamBh9n8dEMBTMeta || {},
    alias: teamBh9n8dEMBTMeta?.alias || [],
    redirect: teamBh9n8dEMBTMeta?.redirect,
    component: () => import("/srv/website/pages/team.vue").then(m => m.default || m)
  },
  {
    name: termsxY3dMBTJtEMeta?.name ?? "terms",
    path: termsxY3dMBTJtEMeta?.path ?? "/agb",
    meta: termsxY3dMBTJtEMeta || {},
    alias: termsxY3dMBTJtEMeta?.alias || [],
    redirect: termsxY3dMBTJtEMeta?.redirect,
    component: () => import("/srv/website/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: dashboardGvWs3YtVJoMeta?.name ?? "user-dashboard",
    path: dashboardGvWs3YtVJoMeta?.path ?? "/kunde/uebersicht",
    meta: dashboardGvWs3YtVJoMeta || {},
    alias: dashboardGvWs3YtVJoMeta?.alias || [],
    redirect: dashboardGvWs3YtVJoMeta?.redirect,
    component: () => import("/srv/website/pages/user/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dataLvw4X2Bm54Meta?.name ?? "user-data",
    path: dataLvw4X2Bm54Meta?.path ?? "/kunde/daten",
    meta: dataLvw4X2Bm54Meta || {},
    alias: dataLvw4X2Bm54Meta?.alias || [],
    redirect: dataLvw4X2Bm54Meta?.redirect,
    component: () => import("/srv/website/pages/user/data.vue").then(m => m.default || m)
  },
  {
    name: invoicesiWOLoz2nlqMeta?.name ?? "user-invoices",
    path: invoicesiWOLoz2nlqMeta?.path ?? "/kunde/rechnungen",
    meta: invoicesiWOLoz2nlqMeta || {},
    alias: invoicesiWOLoz2nlqMeta?.alias || [],
    redirect: invoicesiWOLoz2nlqMeta?.redirect,
    component: () => import("/srv/website/pages/user/invoices.vue").then(m => m.default || m)
  }
]